
import { defineComponent } from 'vue';

//import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import { VueCookieNext } from 'vue-cookie-next';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import axios from 'axios';
import { ElNotification } from 'element-plus';
import Multiselect from '@vueform/multiselect';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'new-physically-disable-report',
  components: {
    Swal,
    axios,
    Datatable,
    Multiselect,
  },
  data() {
    return {
      batch: {
        tranche_id: [] as any,
        start_date: '',
        end_date: '',
      },
      api_url: '',
      data: [],
      total: [],
      tranches_info: [] as any,
      optionsTranche: [] as any,
      componentKey: 0,
      loading: false,
      load: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    this.api_url = this.VUE_APP_API_URL;
    await this.getTranches();
  },
  methods: {
    async printPdf() {
      let formData = new FormData();

      formData.set('tranche_id', this.batch.tranche_id);
      formData.set('start_date', this.batch.start_date);
      formData.set('end_date', this.batch.end_date);

      let data = `${this.VUE_APP_API_URL}/api/report/physicallydisabledprint`;
      axios.post(data, formData).then((response) => {
        //window.open(data, '_blank');
        document.write(response.data);
        window.print();
        location.reload();
        //open the new window and write your HTML to it
      });
    },
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();

      formData.set('tranche_id', this.batch.tranche_id);
      formData.set('start_date', this.batch.start_date);
      formData.set('end_date', this.batch.end_date);
      await ApiService.post('report/physicallydisabledPdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();

      formData.set('tranche_id', this.batch.tranche_id);
      formData.set('start_date', this.batch.start_date);
      formData.set('end_date', this.batch.end_date);

      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/physicallydisabledExport`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'physicallyExport.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async formSubmit() {
      //form submit
    },
    async getTranches() {
      this.load = true;
      await ApiService.get(this.VUE_APP_TRANCHE_LIST_API)
        .then((response) => {
          this.loading = false;
          this.tranches_info = response.data.data;
          this.tranches_info.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.label,
            };
            this.optionsTranche.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },

    trainingProviderNotice() {
      this.loading = true;
      ApiService.get(
        'report/physicallydisabled?tranche_id=' +
        this.batch.tranche_id +
        '&start_date=' +
        this.batch.start_date +
        '&end_date=' +
        this.batch.end_date
      )
        .then((response) => {
          this.loading = false;
          this.data = response.data.data.res;
          this.total = response.data.data.total;
        })
        .catch((response) => {
          this.loading = false;
          console.log(response);
        });
      this.showCourseNotice = true;
    },
  },
  setup() { },
});
